//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'LeftMenu',
  props: {
    miniVariant: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      clipped: true,
      drawer: false,
      elementsWithDivider: ['New sale', 'Services', 'Orders', 'Register', 'Reports', 'Configuration', 'CMS']
    }
  },
  computed: {
    minimize: {
      get() {
        return this.miniVariant
      },
      set(value) {
        this.$emit('variantChanged', value)
      },
    },
    menuItems() {
      return this.$auth.user.menuItems
    },
  },
}
