//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CloseBtn from '@/components/shared/close-btn'
import SecondaryButton from '@/components/shared/secondary-button'
import CustomCombo from '@/components/shared/custom-combo'
import {mapActions, mapMutations, mapState} from 'vuex'
import PrimaryButton from "@/components/shared/primary-button";
import {pinRules} from "@/utils/validationRules";
import { VOtpInput } from "vuetify/lib";
import {pinMixin} from "@/mixins/pinMixin";
import {userCategoryCodes} from "@/enums/userCategoryCodes";

export default {
  name: 'SwitchUser',
  mixins:[pinMixin],
  components: {
    PrimaryButton,
    CloseBtn,
    CustomCombo,
    SecondaryButton,VOtpInput
  },
  data() {
    return {
      pin: '',
      pinRules,
      validPin: false,
      password:''
    }
  },
  methods: {
    onClose() {
      this.pin = ''
      this.$emit('close')
    },
    async switchUser(e) {
      if(e) e.preventDefault();

      if (!this.validPin){
        this.$toast.error(this.$t('invalid_pin'))
        return
      }

      const loginInfo = await this.loginWithPinIfExists(this.pin)
      const oldRedirect = this.$auth.options.redirect
      try {
        let newRedirect = {
          login: this.$route.path,
          logout: this.$route.path,
          callback: this.$route.path,
          home: '/',
        }
        this.$auth.options.redirect = newRedirect

        const response = await this.$auth.loginWith('local',
          {
            data: {
              username: loginInfo.userName,
              password: loginInfo.password,
              categoryCode: userCategoryCodes.WEBPOS_USERS
            }}
        )

        if (response.status === 200 && response.data.token) {
          this.onClose()
          this.$toast.success(this.$t('switched_user'))
          this.resetStores()
          await this.fetchStores()

          if (this.stores && this.stores.length > 0) {

            if (this.stores.length === 1) {
              this.setSwitchedUser({value: false})
              this.setCurrentStore(this.stores[0])
            }
            else if (this.stores.length > 1) {
              this.setSwitchedUser({value: true})
            }

          }

        } else {
          this.$toast.error(this.$t('unable_to_switch'))
        }
      } catch (err) {
        this.$toast.error(this.$t('unable_to_switch'))
      }
      finally {
        this.$auth.options.redirect = oldRedirect
      }
    },
    ...mapActions('userLoginsForPinLogin', ['updateSavedLogins','loginWithPinIfExists']),
    ...mapActions('common/stores',['fetchStores']),
    ...mapActions("currentStore", ["setCurrentStore"]),
    ...mapMutations('currentStore',['setSwitchedUser']),
    ...mapMutations('common/stores',['resetStores']),
    ...mapMutations('cartItems',['resetItems'])
  },
  computed:{
    ...mapState("common/stores", ["stores"]),
  }
}
