//
//
//
//
//
//

export default {
  name: 'CloseBtn',
  methods: {
    click() {
      this.$emit('click')
    },
  },
}
